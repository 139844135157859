import jQuery from 'jquery';

(($) => {
    $(".pricing-table-section input").change(function () {
        $('.cell-elmt').removeClass("text-white bg-primary");
        $(".pricing-table-section input:checked").parent().parent().addClass("text-white bg-primary");
        $(".pricing-table-section input").removeClass('is-invalid');
        $('.tab-pane').removeClass('show active');
        try {
            var val = $(".pricing-table-section input:checked")[0].value;
            $(`#${val}`).addClass('show active');
        } catch (error) {
        }

    });
    $(".pricing-table-section input:checked").parent().parent().addClass("text-white bg-primary");

})(jQuery);